<template>
	<div>
		<Header :title="isEdit ? '编辑推广券活动' : isSee ? '查看推广券活动' : '新增推广券活动'" @back="onBack(false)"></Header>
		<a-spin :spinning="loading">
			<a-form style="margin-top: 20px;" ref="form" name="form" :model="modelRef" scrollToFirstError
				:label-col="{ span: 4 }" :wrapper-col="{ span: 18 }" @finish="onSubmit">
				
				<a-form-item class="ui-form__item" name="organizationId" label="影院组织" :rules="[{ required: true, message: '必选项不允许为空' }]">
					<a-select :disabled="isSee" placeholder="请选择" v-model:value="modelRef.organizationId" @change="(id) => getAllCinemaList(id)">
						<a-select-option v-for="item in organizationList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
					</a-select>
				</a-form-item>
				
				<a-form-item class="ui-form__item" name="cinemaId" label="所属影院" :rules="[{ required: true, message: '必选项不允许为空' }]">
					<a-select :disabled="isSee" placeholder="请选择" v-model:value="modelRef.cinemaId" @change="onCinemaChange">
						<a-select-option v-for="item in cinemaList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
					</a-select>
				</a-form-item>
				
				<a-form-item label="活动名称" name="title" :rules="[{ required: true, message: '必选项不允许为空' }]">
					<a-input :disabled="isSee" v-model:value="modelRef.title" placeholder="请输入活动名称"></a-input>
				</a-form-item>
				
				<a-form-item label="券类型" name="couponType" :rules="[{ required: true, message: '必填项不允许为空' }]">
					<a-radio-group :disabled="isSee" v-model:value="modelRef.couponType" button-style="solid" @change="onCouponChange">
						<a-radio-button :value="1">兑换券</a-radio-button>
						<a-radio-button :value="3">卖品券</a-radio-button>
					</a-radio-group>
				</a-form-item>
				
				<a-form-item :label="modelRef.couponType === 1 ? '兑换券批次' : '卖品券批次'" name="voucherImportId" required>
					<a-form-item-rest>
						<exchangeList v-model:value="modelRef.voucherImportId" :selectItem="voucherImportList" :id="modelRef.cinemaId" :couponType="modelRef.couponType" from="extension" @change="onExchange"></exchangeList>
						<span style="padding-left: 10px;" v-if="modelRef.voucherImportId.length">已选择批次号</span>
						<div v-if="voucherImportList.length" style="margin-top: 10px;">
							<a-table rowKey="id" :columns="columns" :dataSource="voucherImportList" :pagination="false" :scroll="{ x: modelRef.couponType === 1 ? 1200 : 900 }">
								<template #bodyCell="{ column, record }">
									<template v-if="column.key === 'createdTime'">
										{{ transDateTime(record.createTime) }}
									</template>

									<template v-if="column.key === 'time'">
										<div v-if="record.validPeriodType == 7">
											领取后{{ record.period }}天内有效
										</div>
										<div v-else>
											{{ transDateTime(record.receiveStartTime, 1) }} - {{ transDateTime(record.receiveEndTime, 1) }}
										</div>
									</template>

									<template v-if="column.key === 'redeemedCount'">
										{{ record.importCount - record.redeemedCount }}
									</template>

									<template v-if="column.key === 'isHoliday'">
										<div v-if="record.isHoliday">通兑</div>
										<div v-else>非通兑</div>
									</template>
								</template>
							</a-table>
						</div>
					</a-form-item-rest>
				</a-form-item>
				
				<a-form-item label="发放数量" name="issuedNum" :rules="[{ required: true, message: '必填项不允许为空' }]">
					<a-input-number :disabled="isSee" v-model:value="modelRef.issuedNum" :min="0" :precision="0" placeholder="请输入" />
				</a-form-item>
				
				<a-form-item label="每人限领张数" name="restrictionNum" :rules="[{ required: true, message: '必填项不允许为空' }]">
					<a-input-number :disabled="isSee" v-model:value="modelRef.restrictionNum" :min="0" :precision="0" placeholder="请输入" />
				</a-form-item>
				
				<a-form-item name="backgroundUrl" label="活动背景图" :rules="[{required: true, message: '必填项不允许为空'}]" extra="长宽比：375px:215px">
				    <a-upload
						:disabled="isSee"
				        v-model:file-list="fileList"
				        list-type="picture-card"
				        action="/admin/ajaxUpload.do"
				        :before-upload="beforeUpload"
				        @change="(info) => handleChange(info, 'backgroundUrl')"
				        @preview="handlePreview"
				    >
				        <div v-if="fileList.length < 1">
				            <Icon icon="PlusOutlined"></Icon>
				            <div style="margin-top: 8px">Upload</div>
				        </div>
				    </a-upload>
				</a-form-item>
				
				<a-form-item label="活动有效期" name='time' :rules="[{required: true, message: '必填项不允许为空'}]">
					<a-range-picker :disabled="isSee" v-model:value="modelRef.time"/>
				</a-form-item>
				
				<a-form-item label="活动规则" name="useRule" :rules="[{ required: true, message: '必填项不允许为空' }]">
					<div style="position: relative;z-index: 0;">
						<Editor v-model:value="modelRef.useRule" :configSetting="{ showFullScreen: false, disabled: isSee }"></Editor>
					</div>
				</a-form-item>
				
				<a-form-item label="是否允许分享" name="isShare" :rules="[{ required: true, message: '必填项不允许为空' }]">
					<a-radio-group :disabled="isSee" v-model:value="modelRef.isShare">
						<a-radio :value="0">否</a-radio>
						<a-radio :value="1">是</a-radio>
					</a-radio-group>
				</a-form-item>
				
				<div v-if="modelRef.isShare">
					<a-form-item label="分享标题" name="shareTitle" :rules="[{ required: true, message: '必填项不允许为空' }]">
						<a-input :disabled="isSee" v-model:value="modelRef.shareTitle" placeholder="请输入分享标题"></a-input>
					</a-form-item>
					
					<a-form-item label="分享图片" name="shareImgUrl" :rules="[{required: true, message: '必填项不允许为空'}]" extra="长宽比：5:4">
					    <a-upload
							:disabled="isSee"
					        v-model:file-list="fileList1"
					        list-type="picture-card"
					        action="/admin/ajaxUpload.do"
					        :before-upload="beforeUpload"
					        @change="(info) => handleChange(info, 'shareImage')"
					        @preview="handlePreview"
					    >
					        <div v-if="fileList1.length < 1">
					            <Icon icon="PlusOutlined"></Icon>
					            <div style="margin-top: 8px">Upload</div>
					        </div>
					    </a-upload>
					</a-form-item>
				</div>
				
				<a-form-item label="是否启用" name="isDisabled" :rules="[{ required: true, message: '必填项不允许为空' }]">
					<a-radio-group :disabled="isSee" v-model:value="modelRef.isDisabled">
						<a-radio :value="0">启用</a-radio>
						<a-radio :value="1">禁用</a-radio>
					</a-radio-group>
				</a-form-item>
				
				<a-form-item>
					<div style="text-align: center;">
						<a-button type="primary" v-if="!isSee" html-type="submit">提交</a-button>
						<a-button style="margin-left: 20px;" @click="onBack(false)">返回</a-button>
					</div>
				</a-form-item>
			</a-form>
			
			<a-modal :visible="previewVisible" :maskClosable="false" :footer="null" @cancel="handleCancel">
			    <img alt="example" style="width: 100%" :src="previewImage" />
			</a-modal>
		</a-spin>
	</div>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
	import Header from '@/components/header/header.vue';
	import Editor from '@/components/editor/editor.vue';
	import exchangeList from '@/components/couponRecord/index.vue';
	import { getOrganizationList, getCinemaList } from '@/service/modules/cinema.js';
	import { getPromotionActivitieDetail, savePromotionActivitie, updatePromotionActivitie } from '@/service/modules/advert.js';
	export default {
		components: { Icon, Header, Editor, exchangeList},
		props: {
			isEdit: {
				type: Boolean,
				default: false
			},
			isSee: {
				type: Boolean,
				default: false
			},
			id: {
				type: Number,
				default: 0
			}
		},
		data() {
			return {
				loading: false,
				modelRef: {
					couponType: 1,
					isDisabled: 0,
					isShare: 0,
					voucherImportId: []
				},
				organizationList: [],
				cinemaList: [],
				fileList: [],
				fileList1: [],
				previewVisible: false,
				columns: [],
				exchangeColumns: [{
					title: '批次号',
					dataIndex: 'batchNo'
				}, {
					title: '批次名称',
					dataIndex: 'name'
				}, {
					title: '有效期',
					key: 'time'
				}, {
					title: '是否通兑',
					key: 'isHoliday',
					width: 100
				}, {
					title: '补差金额（元）',
					dataIndex: 'diffPrice',
					width: 130
				}, {
					title: '兑换券数量',
					dataIndex: 'importCount',
					width: 130
				}, {
					title: '已绑定数量',
					dataIndex: 'bindCount',
					width: 130
				}, {
					title: '未兑换数量',
					key: 'redeemedCount',
					width: 130
				}],
				snackColumns: [{
					title: '批次号',
					dataIndex: 'batchNo'
				}, {
					title: '有效期',
					key: 'time'
				}, {
					title: '卖品券数量',
					dataIndex: 'importCount',
					width: 130
				}, {
					title: '已绑定数量',
					dataIndex: 'bindCount',
					width: 130
				}, {
					title: '未兑换数量',
					key: 'redeemedCount',
					width: 130
				}],
				voucherImportList: [],
			}
		},
		created() {
			this.columns = this.exchangeColumns;
			this.getOrganizationList();
			if (this.id && (this.isSee || this.isEdit)) {
				this.getPromotionActivitieDetail();
			}
		},
		methods: {
			onBack(isRef) {
				this.$emit('back', isRef);
			},
			async onSubmit() {
				if (this.modelRef.voucherImportId.length === 0) {
					return this.$message.warn('请选择兑换券批次');
				}
				this.loading = true;
				let postData = JSON.parse(JSON.stringify(this.modelRef));
				postData.voucherImportId = postData.voucherImportId.join(',');
				delete postData.time;
				postData.activityStartTime = parseInt(this.modelRef.time[0].startOf('day').valueOf() / 1000);
				postData.activityEndTime = parseInt(this.modelRef.time[1].endOf('day').valueOf() / 1000);
				if (!postData.isShare) {
					postData.shareImgUrl = '';
					postData.shareTitle = '';
				}
				let ret;
				try {
					if (this.isEdit) {
						ret = await updatePromotionActivitie(postData);
					} else {
						ret = await savePromotionActivitie(postData);
					}
					this.loading = false;
					if (ret.code === 200) {
						//this.$message.success('新增【' + postData.batchNo + '】批次号兑换券成功');
						if (this.isEdit) {
							this.$message.success('编辑活动成功!');
						} else {
							this.$message.success('新增活动成功！');
						}
						this.onBack(true);
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getPromotionActivitieDetail() {
				this.loading = true;
				try {
					let ret = await getPromotionActivitieDetail({
						id: this.id
					});
					this.loading = false;
					if (ret.code === 200) {
						delete ret.data.createUser;
						delete ret.data.createTime;
						delete ret.data.updateTime;
						delete ret.data.updateUser;
						this.fileList = [{
							url: ret.data.backgroundUrl,
							uid: 0,
							name: ret.data.backgroundUrl
						}];
						if (this.isShare) {
							this.fileList1 = [{
								url: ret.data.shareImgUrl,
								uid: 0,
								name: ret.data.shareImgUrl
							}];
						}
						ret.data.voucherImportId = [ret.data.voucherImportId];
						ret.data.time = [this.moment(ret.data.activityStartTime * 1000), this.moment(ret.data.activityEndTime * 1000)];
						this.getAllCinemaList(ret.data.organizationId);
						this.modelRef = ret.data;
						this.voucherImportList = [ret.data.adminVoucherImportRecordVO];
						if (ret.data.couponType === 1) {
							this.columns = this.exchangeColumns;
						}
						if (ret.data.couponType === 3) {
							this.columns = this.snackColumns;
						}
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getOrganizationList() {
				let ret = await getOrganizationList({
					page: 1,
					pageSize: 999999
				});
				if (ret.code === 200) {
					this.organizationList = ret.data.list;
				}
			},
			async getAllCinemaList(organizationId) {
				this.cinemaAllList = [];
				this.modelRef.cinemaId = undefined;
				this.onCinemaChange();
				let ret = await getCinemaList({
					page: 1,
					pageSize: 999999,
					organizationId: organizationId ? organizationId : undefined
				})
				if (ret.code === 200) {
					this.cinemaList = ret.data.list;
				}
			},
			onCinemaChange() {
				this.modelRef.voucherImportId = [];
				this.voucherImportList = [];
			},
			onCouponChange(value) {
				this.voucherImportList = [];
				this.modelRef.voucherImportId = [];
				if (value === 1) {
					this.columns = this.exchangeColumns;
				}
				if (value === 3) {
					this.columns = this.snackColumns;
				}
				this.$forceUpdate();
			},
			onExchange(list) {
				this.voucherImportList = list;
			},
			beforeUpload(file) {
			    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
			    if (!isJpgOrPng) {
			        this.$message.error('上传文件格式不正确');
			    }
			    const isLt10M = file.size / 1024 / 1024 < 10;
			    if (!isLt10M) {
			    this.$message.error('上传文件太大了');
			    }
			    return isJpgOrPng && isLt10M;
			},
			handleChange(info, key) {
			    if (info.file.status === 'done') {
					if (key === 'backgroundUrl') {
						this.fileList = info.fileList;
						const $fileList = [...this.fileList];
						this.fileList = $fileList.map(file => {
						    if (file.response) {
								this.modelRef.backgroundUrl = file.response.data.imgUrl;
						        file.url = file.response.data.imgUrl;
						    }
						    return file;
						});
					} else {
						this.fileList1 = info.fileList;
						const $fileList = [...this.fileList1];
						this.fileList1 = $fileList.map(file => {
						    if (file.response) {
								this.modelRef.shareImgUrl = file.response.data.imgUrl;
						        file.url = file.response.data.imgUrl;
						    }
						    return file;
						});
					}
			    }
			},
			handlePreview(file) {
			    this.previewImage = file.url || file.thumbUrl;
			    this.previewVisible = true;
			},
			handleCancel() {
			    this.previewVisible = false;
			},
		}
	}
</script>

<style>
</style>
