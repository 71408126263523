<template>
	<div>
		<Header title="发放信息列表" @back="onBack(false)"></Header>
		<a-spin :spinning="loading">
			<a-form style="margin-top: 20px;" ref="form" :model="formState" name="form" @finish="onSearch">
				<a-row>
					<a-form-item class="ui-form__item" label="会员昵称" name="nickname">
						<a-input v-model:value="formState.nickname" placeholder="请输入会员昵称"></a-input>
					</a-form-item>
					
					<a-form-item class="ui-form__item" label="会员手机号" name="phone">
						<a-input v-model:value="formState.phone" placeholder="请输入会员手机号"></a-input>
					</a-form-item>
					
					<a-form-item class="ui-form__item" label="领取时间">
						<a-range-picker v-model:value="time" showTime></a-range-picker>
					</a-form-item>
				</a-row>
				
				<a-row>
					<a-col :span="18">
						<span v-permission="['operation_extension_list_export']">
							<exportModal type="activityCouponClaimRecord" :searchData="{ ...searchData, activityId: id }"></exportModal>
						</span>
					</a-col>
					<a-col :span="6" style="text-align: right;">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>			
			
			<div style="margin-top: 20px;">
				<a-table :pagination="pagination" rowKey="id" :columns="columns" :dataSource="list">
					<template #bodyCell="{ column, record }">
						<template v-if="column.key === 'time'">
							{{ transDateTime(record.claimTime)}}
						</template>
						<template v-if="column.key === 'couponType'">
							{{ ['兑换券', '', '卖品券'][record.couponType - 1] }}
						</template>
					</template>
				</a-table>
			</div>
		</a-spin>
	</div>
</template>

<script>
	import Header from '@/components/header/header.vue';
	import { Icon } from '@/components/icon/icon.js';
	import exportModal from '@/components/exportReport/exportReport.vue';
	import { getActiveRecord } from '@/service/modules/advert.js';
	export default {
		components: {Header,Icon, exportModal},
		props: {
			id: {
				type: Number,
				default: 0
			}
		},
		data() {
			return {
				loading: false,
				formState: {},
				time: [],
				searchData: {},
				showModal: false,
				list: [],
				columns: [{
					title: '活动名称',
					dataIndex: 'activityName'
				}, {
					title: '会员昵称',
					dataIndex: 'nickname'
				}, {
					title: '会员手机号',
					dataIndex: 'phone'
				}, {
					title: '领取时间',
					key: 'time'
				}, {
					title: '券类型',
					key: 'couponType'
				}, {
					title: '券号',
					dataIndex: 'barcode'
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
			}
		},
		created() {
			this.getData();
		},
		methods: {
			onBack(isRef) {
				this.$emit('back', isRef);
			},
			onSearch() {
				this.pagination.current = 1;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				if (this.time && this.time.length) {
					this.searchData.startTime = this.time[0].unix();
					this.searchData.endTime = this.time[1].unix();
				}
				// //this.pagination.pageSize = 10;
				this.getData();
			},
			reset() {
				this.time = [];
				this.$refs.form.resetFields();
				this.onSearch();
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getActiveRecord({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						activityId: this.id,
						...this.searchData
					})
					this.loading = false;
					if (ret.code === 200) {
						this.pagination.total = ret.data.totalCount;
						this.list = ret.data.list;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			onAdd() {
				this.showModal = true;
				this.$nextTick(() => {
					this.$refs.addForm.resetFields();
				});
			},
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>
